import React, { useEffect, useState, useRef } from 'react';
import { Stage, Layer, Rect, Text, Image, Transformer } from 'react-konva';
import axios from 'axios';
import stickersData from './stickers.json';

const canvasWidth = 1920;
const canvasHeight = 1080;

const skinIdReference = {
  "7_legacy":474,
  "8_legacy":455,
  "9_legacy":788,
  "63_legacy":298,
  "1_legacy":945,
  "2_legacy":895,
  "10_legacy":154,
  "3_legacy":837,
  "11_legacy":1034,
  "13_legacy":460,
  "4_legacy":278,
  "14_legacy":902,
  "60_legacy":301,
  "16_legacy":255,
  "17_legacy":965,
  "27_legacy":1089,
  "23_legacy":888,
  "33_legacy":1133,
  "34_legacy":609,
  "28_legacy":298,
  "35_legacy":286,
  "32_legacy":951,
  "36_legacy":1248,
  "19_legacy":359,
  "26_legacy":829,
  "64_legacy":1232,
  "38_legacy":298,
  "39_legacy":598,
  "40_legacy":361,
  "29_legacy":953,
  "30_legacy":520,
  "24_legacy":704,
  "61_legacy":1031,
  "25_legacy":1021,
  "7_new":122,
  "8_new":246,
  "9_new":1026,
  "63_new":453,
  "1_new":37,
  "2_new":28,
  "10_new":47,
  "3_new":210,
  "11_new":229,
  "13_new":842,
  "4_new":3,
  "14_new":22,
  "60_new":1017,
  "16_new":730,
  "17_new":246,
  "27_new":39,
  "23_new":753,
  "33_new":649,
  "34_new":39,
  "28_new":28,
  "35_new":3,
  "32_new":246,
  "36_new":27,
  "19_new":1020,
  "26_new":1083,
  "64_new":523,
  "38_new":46,
  "39_new":702,
  "40_new":253,
  "29_new":246,
  "30_new":242,
  "24_new":37,
  "61_new":796,
  "25_new":96,
  "31_new":1172,
  "00_test":1 
};

function App() {
  const [weaponsData, setWeaponsData] = useState([]);
  const [selectedWeaponKey, setSelectedWeaponKey] = useState('');
  const [slots, setSlots] = useState([]);
  const [imageURL, setImageURL] = useState(null);
  const [imageElement, setImageElement] = useState(null);
  const [apiKey, setApiKey] = useState(''); 
  const [userEnteredSkinId, setUserEnteredSkinId] = useState('');

  const [floatscale, setFloatscale] = useState(0); // State for floatscale

  const [selectedId, setSelectedId] = useState(null); // State to track the selected shape
  const transformerRef = useRef(null); // Ref for the Transformer component


  const [stickerImage, setStickerImage] = useState(null);

  const [selectedbackgroundslots, setSelectedBackgroundSlots] = useState([]);
  const [selectedbackgroundstickerid, setSelectedBackgroundStickerId] = useState('');
  const [loading, setLoading] = useState(false);

useEffect(() => {
  const image = new window.Image();
  image.src = 'https://steamcdn-a.akamaihd.net/apps/730/icons/econ/stickers/paris2023/flux_gold_large.a602f525f05bbb9d3a591d9d26c886e568d3741e.png'; 
  image.onload = () => {
    setStickerImage(image);
  };
}, []);


// useEffect(() => {
//   // When the component mounts
//   document.body.style.overflow = 'hidden';

//   // When the component unmounts
//   return () => {
//     document.body.style.overflow = 'unset';
//   };
// }, []);

///////////////////////////
  useEffect(() => {
    // Check if the transformer is mounted and a shape is selected
    if (transformerRef.current && selectedId) {
      // Here we need to manually attach or re-attach the Transformer node to the selected shape
      const selectedNode = transformerRef.current.getStage().findOne(`#${selectedId}`);
      transformerRef.current.nodes([selectedNode]);
      transformerRef.current.getLayer().batchDraw();
    }
  }, [selectedId]);
  /////////////

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (!selectedId) return; // No shape is selected
  
      // Find the selected shape
      const shape = slots.find(slot => `slot-${slot.slotNumber}` === selectedId);
      if (!shape) return;
  
      // Destructuring to get the current positions
      let { imgx, imgy } = shape;
  
      // Convert the pressed key to lowercase to handle both lowercase and uppercase
      const key = e.key.toLowerCase();
  
      switch (key) {
        case 'w': // Up
          imgy -= 0.1;
          break;
        case 's': // Down
          imgy += 0.1;
          break;
        case 'a': // Left
          imgx -= 0.1;
          break;
        case 'd': // Right
          imgx += 0.1;
          break;
        default:
          return; // Exit if it's not WASD
      }
  
      // Update the position of the shape in the slots array
      const updatedSlots = slots.map(slot => {
        if (`slot-${slot.slotNumber}` === selectedId) {
          // Make sure to update the properties that are used for positioning in Konva
          return { ...slot, imgx, imgy };
        }
        return slot;
      });
      console.log(updatedSlots)
      setSlots(updatedSlots);
    };
  
    window.addEventListener('keydown', handleKeyDown);
  
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedId, slots]); 
  

  //////////////////

  useEffect(() => {
    if (imageURL) {
      const img = new window.Image();
      img.src = imageURL;
      img.onload = () => {
        setImageElement(img);
      };
    }
  }, [imageURL]);

  useEffect(() => {
    if (apiKey) {
      fetchWeaponsData();
    }
  }, [apiKey]);


  const fetchWeaponsData = async () => {
    try {
      const url = 'https://api.cs2inspects.com/stickerdata';
      //const url = 'http://localhost:3001/stickerdata';
      const response = await axios.get(url, {
        headers: {
          'apikey': apiKey
        }
      });
      setWeaponsData(response.data);
  
    } catch (error) {
      console.error('Error fetching weapons data:', error);
    }
  };

  const handleChange = (event) => {
    const selectedId = event.target.value;
    setSelectedWeaponKey(selectedId);
  
    const selectedWeapon = weaponsData.find((weapon) => weapon._id === selectedId);
  
    if (selectedWeapon) {
      const slotsArray = selectedWeapon.slot_data.map((slot) => ({
        slotNumber: slot.slot_number,
        x: slot.x,
        y: slot.y,
        imgx: slot.imgx,
        imgy: slot.imgy,
        rotation: slot.rotation,
        width: slot.width,
        height: slot.height,
        visible: slot.visible, //
        confirmed: slot.confirmed, //
        placement: slot.placement, //
        note: slot.note //
      }));
      setSlots(slotsArray);
      console.log(slotsArray);

      // const defaultSlotsData = selectedWeapon.defaultslot_data || []; // Assuming defaultslot_data is part of your weapon data structure
      // setDefaultSlots(defaultSlotsData);

      // Check if defaultslot_data exists and has entries, otherwise set defaults
      if (!selectedWeapon.defaultslot_data || selectedWeapon.defaultslot_data.length === 0) {
        // Define your default slots structure here
        const defaultSlotsDefaults = [
          { stickerbox: "stickerbox1", slot: 0, defaultslot: 0, defaultr: 0, defaultx: 0, defaulty: 0 },
          { stickerbox: "stickerbox2", slot: 1, defaultslot: 1, defaultr: 0, defaultx: 0, defaulty: 0 },
          { stickerbox: "stickerbox3", slot: 2, defaultslot: 2, defaultr: 0, defaultx: 0, defaulty: 0 },
          { stickerbox: "stickerbox4", slot: 3, defaultslot: 3, defaultr: 0, defaultx: 0, defaulty: 0 },
          { stickerbox: "stickerbox5", slot: 0, defaultslot: 0, defaultr: 0, defaultx: 0, defaulty: 0 },
        ];
        setDefaultSlots(defaultSlotsDefaults);
      } else {
        setDefaultSlots(selectedWeapon.defaultslot_data);
      }
      
      //console.log(defaultSlotsData);
      setFloatscale(selectedWeapon.floatscale); // Set floatscale value
      // skin ID
      const skinId = skinIdReference[selectedId];
      const itemId = selectedId.split('_')[0];
      let imageURL;

      if (skinId) {
        // If skinId exists in the reference, use the regular link pattern
        imageURL = `https://i.cs2inspects.com/images/itemid_skinid_stickerupdater/${itemId}_${skinId}.png`;
      } else {
        // If skinId does not exist in the reference, use a fallback or prompt for input
        console.log('Skin ID not found in reference, awaiting user input or using fallback.');
        // For demonstration, using a fallback URL or you can later update it based on user input
        imageURL = `https://i.cs2inspects.com/images/itemid_skinid/${itemId}_${skinId}.webp`;
      }
  
      setImageURL(imageURL);
      console.log('Set imageURL:', imageURL);
    }
  };

  const updateBackgroundImage = () => {
    const itemId = selectedWeaponKey.split('_')[0]; // Extract the item ID from the selected weapon key
    const lookupSkinId = skinIdReference[selectedWeaponKey]; // Get the skinId from the skinIdReference object
    const userSkinId = parseInt(userEnteredSkinId, 10); // Convert the user entered skinId to an integer for comparison
  
    console.log('itemId:', itemId, 'lookupSkinId:', lookupSkinId, 'userSkinId:', userSkinId);
  
    let newImageURL;
    if (lookupSkinId === userSkinId) {
      // If user entered skinId matches the lookup skinId, use the regular image link
      newImageURL = `https://i.cs2inspects.com/images/itemid_skinid_stickerupdater/${itemId}_${userSkinId}.png`;
    } else {
      // If it doesn't match, use the stickerupdater version
      newImageURL = `https://i.cs2inspects.com/images/itemid_skinid/${itemId}_${userSkinId}.webp`;
    }
  
    setImageURL(newImageURL); // Update the state with the new image URL
    console.log(newImageURL);
  };
  
const handleSlotDragEnd = (e, slotId) => {
  const newX = e.target.x();
  const newY = e.target.y();

  // Update slots with new positions, respecting the primary positioning rules
  const updatedSlots = slots.map(slot => {
      if (`slot-${slot.slotNumber}` === slotId) {
          // Calculate the adjusted x position based on width and height
          //const adjustedX = newX + (slot.width - slot.height) / 2;
          const adjustedX = slot.imgx + (slot.width - slot.height) / 2 + slot.height * 0.1;
          const adjustedY = slot.imgy + slot.height * 0.1; 

          return {
              ...slot,
              imgx: newX, // Assuming drag updates imgx directly
              imgy: newY, // Direct update to imgy
              x: adjustedX, // Adjusted position
              y: adjustedY,
          };
      }
      return slot;
  });

  setSlots(updatedSlots);
};

const handleTransformEnd = (e, slotId) => {
  const node = e.target;
  // Retrieve the slotId from the node if it's not provided
  const id = slotId || node.id();

  // Get the current scale values to calculate new width and height
  const scaleX = node.scaleX();
  const scaleY = node.scaleY();
  const newWidth = Math.max(5, node.width() * scaleX);
  const newHeight = Math.max(5, node.height() * scaleY);

  // Reset the scale to 1 after applying the size change
  node.scaleX(1);
  node.scaleY(1);
  node.width(newWidth);
  node.height(newHeight);

  const newRotation = node.rotation();

  // Update the slots state with the transformed properties
  const updatedSlots = slots.map(slot => {
    if (`slot-${slot.slotNumber}` === id) {
      //const adjustedX = slot.imgx + (newWidth - newHeight) / 2;
      //const adjustedY = slot.imgy; // imgy remains as the primary y position without adjustment
      const adjustedX = slot.imgx + (slot.width - slot.height) / 2 + slot.height * 0.1;
      const adjustedY = slot.imgy + slot.height * 0.1; 

      return {
        ...slot,
        x: adjustedX, // Adjusted x based on your specific rule
        y: adjustedY, // Directly using imgy as y
        imgx: slot.imgx,
        imgy: slot.imgy,
        width: newWidth,
        height: newHeight,
        rotation: newRotation
      };
    }
    return slot;
  });

  setSlots(updatedSlots);
};

  const updateSlotPositions = async () => {
    // const payload = {
    //   slots: slots.map(slot => ({
    //     slot_number: slot.slotNumber,
    //     x: slot.x,
    //     y: slot.y,
    //   }))
    // };
    const payload = {
      floatscale, // Add floatscale to the payload
      slots: slots.map(slot => ({
          slot_number: slot.slotNumber,
          //x: slot.x,
          //x: slot.imgx + (slot.width - slot.height) / 2,
          //y: slot.imgy,
          x: slot.imgx + (slot.width - slot.height) / 2 + slot.height * 0.1,
          y: slot.imgy + slot.height * 0.1,
          imgx: slot.imgx,
          imgy: slot.imgy,
          width: slot.width, 
          height: slot.height,
          rotation: slot.rotation || 0, 
          visible: slot.visible || false,
          confirmed: slot.confirmed || false,
          placement: slot.placement || false,
          note: slot.note || ''
  //     }))
  // };
      })),
      defaultslots: defaultSlots
      };

  console.log(payload)

    try {
      const url = `https://api.cs2inspects.com/stickerdata/${selectedWeaponKey}`;
      //const url = `http://localhost:3001/stickerdata/${selectedWeaponKey}`;
      const response = await axios.put(url, payload, {
        headers: {
          'apikey': apiKey 
        }
      });
      // If the update is successful, refetch the weapons data
      await fetchWeaponsData();
      console.log(selectedWeaponKey)
      //alert('All slot positions updated successfully!');
    } catch (error) {
      console.error('Error updating slot positions:', error);
      //console.log(error)
      //alert('Failed to update slot positions.');
    }
  };
  
// Function to add a new slot
const addNewSlot = () => {
  const newSlotNumber = slots.length ? Math.max(...slots.map(slot => slot.slotNumber)) + 1 : 0;
  const newSlot = {
    slotNumber: newSlotNumber,
    x: 100, // Default X position, adjust as needed
    y: 100, // Default Y position, adjust as needed
    imgx: 0, // Default imgx
    imgy: 0, // Default imgy
    rotation: 0, // Default rotation
    width: 256, // Default width
    height: 192, // Default height
    visible: true,
  };
  setSlots([...slots, newSlot]);
};

// Function to remove a slot by slotNumber
const removeSlot = (slotNumberToRemove) => {
  setSlots(slots.filter(slot => slot.slotNumber !== slotNumberToRemove));
};

// Function to toggle visibility of all slots
const toggleSlotVisibility = (slotNumber) => {
  const updatedSlots = slots.map(slot => {
    if (slot.slotNumber === slotNumber) {
      return { ...slot, visible: !slot.visible };
    }
    return slot;
  });
  setSlots(updatedSlots);
};

// const handleStageClick = (e) => {
//   // Konva event object gives you the pointer's position
//   const stage = e.target.getStage();
//   const pointerPosition = stage.getPointerPosition();
  
//   console.log(`Click position - x: ${pointerPosition.x}, y: ${pointerPosition.y}`);
// };

const [defaultSlots, setDefaultSlots] = useState([
  { stickerbox: "stickerbox1", slot: 0, defaultslot: 0, defaultr: 0, defaultx: 0, defaulty: 0 },
  { stickerbox: "stickerbox2", slot: 1, defaultslot: 1, defaultr: 0, defaultx: 0, defaulty: 0 },
  { stickerbox: "stickerbox3", slot: 2, defaultslot: 2, defaultr: 0, defaultx: 0, defaulty: 0 },
  { stickerbox: "stickerbox4", slot: 3, defaultslot: 3, defaultr: 0, defaultx: 0, defaulty: 0 },
  { stickerbox: "stickerbox5", slot: 0, defaultslot: 0, defaultr: 0, defaultx: 0, defaulty: 0 }
]);

function updateDefaultSlot(index, field, value) {
  const updated = [...defaultSlots];
  updated[index][field] = value;
  setDefaultSlots(updated);
}

const handleRemoveConfirmation = (slotNumber) => {
  if (window.confirm('Are you sure you want to remove this slot?')) {
    removeSlot(slotNumber);
  }
};

const toggleConfirmation = (slotNumber) => {
  const updatedSlots = slots.map(slot => {
    if (slot.slotNumber === slotNumber) {
      return { ...slot, confirmed: !slot.confirmed };
    }
    return slot;
  });
  setSlots(updatedSlots);
};

const togglePlacement = (slotNumber) => {
  const updatedSlots = slots.map(slot => {
    if (slot.slotNumber === slotNumber) {
      return { ...slot, placement: !slot.placement };
    }
    return slot;
  });
  setSlots(updatedSlots);
};

const updateSlotNote = (slotNumber, note) => {
  const updatedSlots = slots.map(slot => {
    if (slot.slotNumber === slotNumber) {
      return { ...slot, note: note };
    }
    return slot;
  });
  setSlots(updatedSlots);
};


const getStickerImage = (stickerId) => {
  const sticker = stickersData.find((sticker) => sticker.sticker_id === stickerId);
  return sticker ? sticker.sticker_image : null;
};


const handleBackgroundSlotChange = (selected) => {
  setSelectedBackgroundSlots(selected);
};

const handleBackgroundStickerIdChange = (event) => {
  setSelectedBackgroundStickerId(event.target.value);
};

const handleChangeBackground = async () => {
  setLoading(true);
  try {
    const selectedWeaponKeyParts = selectedWeaponKey.split('_');
    const defindex = selectedWeaponKeyParts.length > 1 ? parseInt(selectedWeaponKeyParts[0]) : null;
    const paintindex = skinIdReference[selectedWeaponKey] ? parseInt(skinIdReference[selectedWeaponKey]) : null;
    const stickerId = selectedbackgroundstickerid || '6699';
    
    // Check if defindex and paintindex are not null before sending the request
    if (defindex !== null && paintindex !== null) {
      const payload = {
        module: "cs2_ss3",
        method: "float",
        float_details: {
          paintseed: 0,
          defindex,
          paintindex,
          stickers: selectedbackgroundslots.map((slot) => ({
            stickerId: parseInt(stickerId),
            slot: parseInt(slot),
          })),
          floatvalue: 0,
        },
        inspectlink: "none",
        status_id: 1,
        preset: ["front"],
      };

      const response = await axios.post("https://requests.cs2inspects.com/newreq", payload);
      const { url } = response.data;

      // Once the URL is obtained, update the background image
      setImageURL(url);

      // Update sticker image URL with the new stickerId
      const newStickerImageURL = getStickerImage(stickerId);
      const newStickerImage = new window.Image();
      newStickerImage.src = newStickerImageURL;
      newStickerImage.onload = () => {
        setStickerImage(newStickerImage);
      };
    } else {
      console.log("Defindex or paintindex is null. Skipping background change request.");
    }
  } catch (error) {
    console.error("Error updating background:", error);
    // Handle error here
  } finally {
    setLoading(false); // Set loading back to false when the operation is completed
  }
};

// Define updateSlotHeight function
const updateSlotHeight = (slotNumber, newHeight) => {
  // Update the slot height
  const updatedSlots = slots.map(slot => {
    if (slot.slotNumber === slotNumber) {
      return { ...slot, height: parseFloat(newHeight) };
    }
    return slot;
  });
  setSlots(updatedSlots);
};

// Define updateSlotWidth function
const updateSlotWidth = (slotNumber, newWidth) => {
  // Update the slot width
  const updatedSlots = slots.map(slot => {
    if (slot.slotNumber === slotNumber) {
      return { ...slot, width: parseFloat(newWidth) };
    }
    return slot;
  });
  setSlots(updatedSlots);
};

// Define updateSlotX function
const updateSlotX = (slotNumber, newX) => {
  // Update the slot X coordinate
  const updatedSlots = slots.map(slot => {
    if (slot.slotNumber === slotNumber) {
      return { ...slot, x: parseFloat(newX) };
    }
    return slot;
  });
  setSlots(updatedSlots);
};

// Define updateSlotY function
const updateSlotY = (slotNumber, newY) => {
  // Update the slot Y coordinate
  const updatedSlots = slots.map(slot => {
    if (slot.slotNumber === slotNumber) {
      return { ...slot, y: parseFloat(newY) };
    }
    return slot;
  });
  setSlots(updatedSlots);
};

// Define updateSlotRotation function
const updateSlotRotation = (slotNumber, newRotation) => {
  // Update the slot rotation
  const updatedSlots = slots.map(slot => {
    if (slot.slotNumber === slotNumber) {
      return { ...slot, rotation: parseFloat(newRotation) };
    }
    return slot;
  });
  setSlots(updatedSlots);
};

// Define updateSlotImgX function
const updateSlotImgX = (slotNumber, newImgX) => {
  // Update the image X coordinate
  const updatedSlots = slots.map(slot => {
    if (slot.slotNumber === slotNumber) {
      return { ...slot, imgx: parseFloat(newImgX) };
    }
    return slot;
  });
  setSlots(updatedSlots);
};

// Define updateSlotImgY function
const updateSlotImgY = (slotNumber, newImgY) => {
  // Update the image Y coordinate
  const updatedSlots = slots.map(slot => {
    if (slot.slotNumber === slotNumber) {
      return { ...slot, imgy: parseFloat(newImgY) };
    }
    return slot;
  });
  setSlots(updatedSlots);
};


return (
  <div style={{ backgroundColor: '#333', color: 'white', padding: '20px' }}>
    <select value={selectedWeaponKey} onChange={handleChange} style={{ color: 'black' }}> {/* Ensure select text is readable */}
      <option value="">Select a weapon</option>
      {weaponsData.map((weapon) => (
        <option key={weapon._id} value={weapon._id}>
          {`${weapon.item_id} - ${weapon.item_name} - ${weapon.item_model_version}`}
        </option>
      ))}
    </select> 
    <input type="text" placeholder="Enter API Key" value={apiKey} onChange={(e) => setApiKey(e.target.value)} style={{ color: 'black' }}/>

    <div style={{ marginTop: '20px' }}>
  <table>
    <thead>
      <tr>
        <th>Box</th><th>Real Slot</th><th>Default Slot</th><th>Default Rotation</th><th>Default X</th><th>Default Y</th>
      </tr>
    </thead>
    <tbody>
      {defaultSlots.map((slot, index) => (
        <tr key={index}>
          <td>{slot.stickerbox}</td>
          <td>
            <select
              value={slot.slot}
              onChange={(e) => updateDefaultSlot(index, 'slot', parseInt(e.target.value, 10))}
            >
              {/* Assuming real slots range from 1 to 5 for demonstration */}
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(num => (
                <option key={num} value={num}>{num}</option>
              ))}
            </select>
          </td>
          <td>
            <select
              value={slot.defaultslot}
              onChange={(e) => updateDefaultSlot(index, 'defaultslot', parseInt(e.target.value, 10))}
            >
              {/* Assuming fake slots range from 1 to 5 for demonstration */}
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(num => (
                <option key={num} value={num}>{num}</option>
              ))}
            </select>
          </td>
          <td>
            <input
              type="text"
              value={slot.defaultr}
              onChange={(e) => updateDefaultSlot(index, 'defaultr', parseFloat(e.target.value))}
            />
          </td>
          <td>
            <input
              type="text"
              value={slot.defaultx}
              onChange={(e) => updateDefaultSlot(index, 'defaultx', parseFloat(e.target.value))}
            />
          </td>
          <td>
            <input
              type="text"
              value={slot.defaulty}
              onChange={(e) => updateDefaultSlot(index, 'defaulty', parseFloat(e.target.value))}
            />
          </td>
        </tr>
      ))}
    </tbody>
  </table>
  {/* <button onClick={() => console.log(defaultSlots)}>Update Default Slots</button> */}
</div>


    <div style={{ marginTop: '20px' }}>
      {/* Input field for user to enter a skinId */}
      {/* <input
        type="text"
        placeholder="Different Skin ID"
        value={userEnteredSkinId}
        onChange={(e) => setUserEnteredSkinId(e.target.value)}
        style={{ color: 'black', margin: '10px 0' }}
      /> */}
      {/* Button to update the background image */}

      {/* <button onClick={updateBackgroundImage} style={{ display: 'block', marginBottom: '20px' }}>
        Change Background
      </button>
       */}

      {slots.map((slot, index) => (
        // <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
        //   Slot {slot.slotNumber} - X: {slot.x} Y: {slot.y} R: {slot.rotation} IMG H: {slot.height} IMG W: {slot.width}  <button onClick={() => removeSlot(slot.slotNumber)}>Remove</button>
        // </div>
        <div key={index} style={{ display: 'flex', alignItems: 'center', gap: '10px', marginTop: '10px' }}>
            {/* Slot {slot.slotNumber} - X: {slot.x} Y: {slot.y} R: {slot.rotation} IMG H: {slot.height} IMG W: {slot.width} */}
            Slot {slot.slotNumber} - 
            X: <input
          type="text" 
          value={slot.x}
          onChange={(e) => updateSlotX(slot.slotNumber, e.target.value)}
        />
      Y: <input
          type="text"
          value={slot.y}
          onChange={(e) => updateSlotY(slot.slotNumber, e.target.value)}
        />
      R: <input
          type="text" 
          value={slot.rotation}
          onChange={(e) => updateSlotRotation(slot.slotNumber, e.target.value)}
        />
      IMG X: <input
              type="text" 
              value={slot.imgx}
              onChange={(e) => updateSlotImgX(slot.slotNumber, e.target.value)}
            />
      IMG Y: <input
              type="text" 
              value={slot.imgy}
              onChange={(e) => updateSlotImgY(slot.slotNumber, e.target.value)}
            />
      IMG H: <input
              type="text" 
              value={slot.height}
              onChange={(e) => updateSlotHeight(slot.slotNumber, e.target.value)}
            />
      IMG W: <input
              type="text" 
              value={slot.width}
              onChange={(e) => updateSlotWidth(slot.slotNumber, e.target.value)}
            />
            {/* <button onClick={() => removeSlot(slot.slotNumber)}>Remove</button> */}
            {/* <button onClick={() => handleRemoveConfirmation(slot.slotNumber)}>Remove</button> */}
            <button onClick={() => handleRemoveConfirmation(slot.slotNumber)} style={{ backgroundColor: 'red' }}>Remove</button>

            <button style={{ backgroundColor: slot.confirmed ? 'green' : 'yellow' }} onClick={() => toggleConfirmation(slot.slotNumber)}>
            {slot.confirmed ? 'Confirmed (Placement)' : 'Not Confirmed (Placement)'}
            </button>

            <button style={{ backgroundColor: slot.placement ? 'green' : 'blue' }} onClick={() => togglePlacement(slot.slotNumber)}>
            {slot.placement ? 'Current (Placement)' : 'Legacy (Placement)'}
            </button>

            <input
              type="text"
              value={slot.note || ''}
              onChange={(e) => updateSlotNote(slot.slotNumber, e.target.value)}
              placeholder="Add Note"
            />

            <button onClick={() => toggleSlotVisibility(slot.slotNumber)}>
              {slot.visible ? 'Hide' : 'Show'}
            </button>
          </div>
      ))}
      <button onClick={addNewSlot} style={{ marginTop: '20px' }}>Add Slot</button>
    </div>

    <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', marginBottom: '0px' }}>
        <label style={{ marginRight: '10px' }}>Sticker Float Scale (Sticker GAP):</label>
        <input 
          type="text" 
          value={floatscale} 
          onChange={(e) => setFloatscale(parseFloat(e.target.value))} 
          style={{ marginBottom: '0' }}
        />
      </div>

    {/* <button onClick={updateSlotPositions} style={{ marginTop: '20px' }}>Update [ALL DATA]</button> */}
    <button onClick={updateSlotPositions} style={{ marginTop: '10px', backgroundColor: 'red', color: 'white' }}>Update [THIS ITEM]</button>
{/* 
    <div style={{ marginTop: '20px' }}>
  <label>Slot ID:</label>
  <div style={{ display: 'flex', flexDirection: 'row' }}>
    {[...Array(10)].map((_, index) => (
      <div key={index} style={{ marginRight: '10px' }}>
        <input
          type="checkbox"
          id={`slotId_${index}`}
          value={index}
          onChange={(e) => handleBackgroundSlotChange(e.target.checked ? [...selectedbackgroundslots, e.target.value] : selectedbackgroundslots.filter(slot => slot !== e.target.value))}
        />
        <label htmlFor={`slotId_${index}`}>{index}</label>
      </div>
    ))}
  </div>
  <div>
    <label htmlFor="stickerId">Sticker ID:</label>
    <input type="text" id="stickerId" value={selectedbackgroundstickerid || '6699'} onChange={handleBackgroundStickerIdChange} />
  </div>
  <button onClick={handleChangeBackground}>Change Background</button>
</div> */}
{/* 
<div style={{ marginTop: '20px' }}>
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <label style={{ marginRight: '10px' }}>Slot ID:</label>
    {[...Array(10)].map((_, index) => (
      <div key={index} style={{ marginRight: '10px' }}>
        <input
          type="checkbox"
          id={`slotId_${index}`}
          value={index}
          onChange={(e) => handleBackgroundSlotChange(e.target.checked ? [...selectedbackgroundslots, e.target.value] : selectedbackgroundslots.filter(slot => slot !== e.target.value))}
        />
        <label htmlFor={`slotId_${index}`}>{index}</label>
      </div>
    ))}
  </div>
  <div>
    <label htmlFor="stickerId">Sticker ID:</label>
    <input type="text" id="stickerId" value={selectedbackgroundstickerid || '6699'} onChange={handleBackgroundStickerIdChange} />
  </div>
  <button onClick={handleChangeBackground}>Change Background</button>
</div> */}

<div style={{ marginTop: '20px' }}>
  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
    <label style={{ marginRight: '10px' }}>Slot ID:</label>
    {[...Array(10)].map((_, index) => (
      <div key={index} style={{ marginRight: '10px' }}>
        <input
          type="checkbox"
          id={`slotId_${index}`}
          value={index}
          onChange={(e) => handleBackgroundSlotChange(e.target.checked ? [...selectedbackgroundslots, e.target.value] : selectedbackgroundslots.filter(slot => slot !== e.target.value))}
        />
        <label htmlFor={`slotId_${index}`}>{index}</label>
      </div>
    ))}
  </div>
  <div>
    <label htmlFor="stickerId">Sticker ID:</label>
    <input type="text" id="stickerId" value={selectedbackgroundstickerid || '6699'} onChange={handleBackgroundStickerIdChange} />
  </div>
  <button onClick={handleChangeBackground} disabled={loading}>
    {loading ? 'Changing Background...' : 'Change Background'}
  </button>
  {loading && <div>Loading...</div>}
</div>




    <Stage width={canvasWidth} height={canvasHeight}>
        <Layer>
          {imageElement && <Image image={imageElement} width={canvasWidth} height={canvasHeight} />}
          {slots.map((slot, index) => slot.visible && (
          //{slots.map((slot, index) => (
            <React.Fragment key={`slot-${index}`}>
              {/* Outline for the sticker */}
              <Rect
                x={slot.imgx - 1} // Subtract a small value to position the outline slightly larger than the image
                y={slot.imgy - 1} // Subtract a small value for the same reason as above
                width={slot.width + 2} // Increase width by the outline's thickness
                height={slot.height + 2} // Increase height by the outline's thickness
                stroke="yellow" // Outline color
                strokeWidth={2} // Outline thickness
              />
              {/* Sticker Image */}
              {/* <Rect */}
              <Image
                id={`slot-${slot.slotNumber}`} // Assign a unique ID for selection
                x={slot.imgx}
                y={slot.imgy}
                width={slot.width}
                height={slot.height}
                //fill="red"
                image={stickerImage}
                draggable
                //onDragEnd={(e) => handleSlotDragEnd(slot.slotNumber, e)}
                //onDragEnd={(e) => handleSlotDragEnd(e, `slot-${slot.slotNumber}`)}
                onDragEnd={e => handleSlotDragEnd(e, `slot-${slot.slotNumber}`)}
                onTransformEnd={handleTransformEnd} // Attach the handler here
                rotation={slot.rotation || 0} // Apply rotation from state
                //onClick={() => setSelectedId(`slot-${slot.slotNumber}`)} // Set the selectedId when a rectangle is clicked
                onClick={(e) => {
                  // First action: Set the selected ID
                  setSelectedId(`slot-${slot.slotNumber}`);
              
                  // Second action: Log click position or any other action
                  const stage = e.target.getStage();
                  const pointerPosition = stage.getPointerPosition();
                  console.log(`Click position - x: ${pointerPosition.x}, y: ${pointerPosition.y}`);
                }} // Modify this line to include multiple actions
                onTap={() => setSelectedId(`slot-${slot.slotNumber}`)} // Same for tap events (mobile)
              />

          {/* <Rect   //////KEEEP THIS
              x={slot.imgx + (slot.width - slot.height) / 2} // Center horizontally for the original square
              y={slot.imgy} // Align to the top for the original square
              width={slot.height} // Original square's width matches the image's height
              height={slot.height} // Original square's height matches the image's height
              stroke="red" // Color of the outline for the original square
              strokeWidth={2} // Thickness of the outline for the original square
              onDragEnd={e => handleSlotDragEnd(e, `slot-${slot.slotNumber}`)}
          />

          <Rect  //////KEEEP THIS
              x={slot.imgx + (slot.width - slot.height) / 2 + slot.height * 0.1} // Adjusted to center the smaller square horizontally
              y={slot.imgy + slot.height * 0.1} // Adjusted to align the smaller square with a 10% reduction in size
              width={slot.height * 0.8} // 90% of the original square's width
              height={slot.height * 0.8} // 90% of the original square's height
              stroke="yellow" // Color of the outline for the smaller square
              strokeWidth={2} // Thickness of the outline for the smaller square
              onDragEnd={e => handleSlotDragEnd(e, `slot-${slot.slotNumber}`)}
          /> */}

          <Text
            x={slot.imgx - 50}
            y={slot.imgy + 110 } // Position the text at the bottom of the slot. Adjust as needed.
            text={`${slot.slotNumber}`}
            fontSize={24} // Adjust font size as needed
            fontFamily="Arial" // Set the font family as needed
            fill="red" // Text color
            width={slot.width} // Ensure the text aligns within the slot width
            align="center" // Center the text
          />
            </React.Fragment>
          ))}
          <Transformer ref={transformerRef} />
        </Layer>
      </Stage>
  </div>
);
}

export default App;
